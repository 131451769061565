import Link from 'gatsby-link'
import React, { Component } from 'react'
import styled from 'styled-components'
import { LogoMark } from '../components/Images'
import Layout from '../components/Layout'
import Section from '../components/Section'
import Type from '../components/Type'
import Wrapper from '../components/Wrapper'
import { injectIntl, defineMessages } from 'gatsby-plugin-intl'

export const messageDescriptors = defineMessages({
  title: {
    id: 'pages.jojo.title',
    defaultMessage: 'Jojo Hedaya',
  },
  description: {
    id: 'pages.jojo.description',
    defaultMessage: 'Jojo Hedaya, CEO of Unroll.Me - Career history & biography'
  },
  heading: {
    id: 'pages.jojo.heading',
    defaultMessage: 'Biography of Jojo Hedaya'
  },
  subHeading: {
    id: 'pages.jojo.subHeading',
    defaultMessage: "Learn more about Unroll.Me's CEO Jojo Hedaya"
  },

  section1Part1: {
    id: 'pages.jojo.section1Part1',
    defaultMessage: 'Jojo Hedaya is the'
  },
  section1Part2: {
    id: 'pages.jojo.section1Part2',
    defaultMessage: 'Chief Product and Consumer Officer'
  },
  section1Part3: {
    id: 'pages.jojo.section1Part3',
    defaultMessage: `for Rakuten Intelligence, leading the product strategy and
                  development for the company’s consumer brands. As a founder
                  and CEO of the company, his entrepreneurial spirit, vision,
                  and business acumen were major drivers behind Rakuten
                  Intelligence’s acquisition of the company in 2011.`
  },

  section2Part1: {
    id: 'pages.jojo.section2Part1',
    defaultMessage: 'Jojo has been dreaming up new business ideas since he was a teenager. He enrolled in Brooklyn College, during which time'
  },
  section2Part2: {
    id: 'pages.jojo.section2Part2',
    defaultMessage: 'he created Unroll.Me'
  },
  section2Part3: {
    id: 'pages.jojo.section2Part3',
    defaultMessage: 'with his co-founders. In just a few years, Unroll.Me became a smash success, with the iOS app getting a top rank in the App Store.'
  },
  section3Part1: {
    id: 'pages.jojo.section3Part1',
    defaultMessage: 'Jojo has a passion for developing thriving office cultures and mentoring his employees to be the best versions of themselves. He has spoken at various entrepreneurial-themed events including The Hybrid Conference and'
  },
  section3Part2: {
    id: 'pages.jojo.section3Part2',
    defaultMessage: 'Startup Grind'
  },
  section3Part3: {
    id: 'pages.jojo.section3Part3',
    defaultMessage: ', and is a frequent speaker at local and community events. In his spare time, Jojo enjoys watching the Knicks, traveling, and spending time with his wife and young son.'
  }
})

class IndexPage extends Component {
  render() {
    const { intl } = this.props
    const messages = Object.keys(messageDescriptors).reduce((messages, key) => {
      messages[key] = intl.formatMessage(messageDescriptors[key])
      return messages
    }, {})
    return (
      <Layout
        title={messages.title}
        description={messages.description}
        clean
      >
        <LogoWrapper to="/">
          <Logo src={LogoMark} />
        </LogoWrapper>
        <Section large>
          <Wrapper large>
            <Container>
              <Content>
                <Type variant="h1" f3 f2_ns dark_gray mv0 lh_title>
                  {messages.heading}
                </Type>
                <Type variant="h2" f5 f4_ns lh_copy mt1 mb3 gray fw4>
                  {messages.subHeading}
                </Type>
                <Type variant="p" f5 lh_copy dark_gray>
                  {messages.section1Part1}{' '}
                  <a href="https://www.linkedin.com/in/jojohedaya">
                    {messages.section1Part2}
                  </a>{' '}
                  {messages.section1Part3}
                </Type>
                <Type variant="p" f5 lh_copy dark_gray>
                  {messages.section2Part1}{' '}
                  <a href="https://www.wework.com/creator/grow-your-business/social-media/unroll-meet-email-organization-experts/">
                    {messages.section2Part2}
                  </a>{' '}
                  {messages.section2Part3}
                </Type>
                <Type variant="p" f5 lh_copy dark_gray>
                  {messages.section3Part1}{' '}
                  <a href="https://www.startupgrind.com/events/details/startup-grind-new-york-city-presents-jojo-hedaya-unrollme">
                    {messages.section3Part2}
                  </a>
                  {messages.section3Part3}
                </Type>
              </Content>
              <Image>
                <img src="/images/Jojo_Hedaya.jpg" alt="Jojo Hedaya" />
              </Image>
            </Container>
          </Wrapper>
        </Section>
      </Layout>
    )
  }
}

const LogoWrapper = styled(props => <Link {...props} />)`
  position: absolute;
  top: 15px;
  left: 15px;
  width: 30px;
  height: 30px;
  @media only screen and (min-width: 600px) {
    width: 60px;
    height: 60px;
  }
`
const Logo = styled.img``

const Container = styled.div`
  margin-top: 20px;
  @media only screen and (min-width: 600px) {
    display: flex;
    margin-top: 5%;
  }
`
const Image = styled.div`
  @media only screen and (min-width: 600px) {
    width: 40%;
    padding-left: 5%;
  }
`
const Content = styled.div`
  @media only screen and (min-width: 600px) {
    width: 60%;
  }
`

export default injectIntl(IndexPage)
